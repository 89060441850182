export default [
  {
    path: "/",
    name: "Home",
    component: () =>
      /* webpackChunkName: "view-[request]" */ import("@/views/Home.vue"),
    // /* webpackChunkName: "view-[request]" */ import("@/views/Auth/index.vue"),
    meta: { requiresAuth: true, title: "Home", Permission: "Exception" },
  },

  {
    path: "/login",
    name: "Login",
    component: () =>
      /* webpackChunkName: "view-[request]" */ import("@/views/Auth/index.vue"),
    meta: { requiresAuth: false },
  },
  {
    path: "/Dashboard",
    name: "dashboard",
    component: () =>
      /* webpackChunkName: "view-[request]" */ import(
        "@/views/Dashboard/index.vue"
      ),
    meta: { requiresAuth: false },
  },
  // {
  //   path: "/notif-claim-submission",
  //   name: "notifclaimsubmission",
  //   component: () =>
  //     /* webpackChunkName: "view-[request]" */ import(
  //       "@/views/Auth/notifclaimsubmission.vue"
  //     ),
  //   meta: { requiresAuth: false },
  // },
  {
    path: "*",
    name: "NotFound",
    component: () =>
      /* webpackChunkName: "view-[request]" */ import(
        "@/components/error/404.vue"
      ),
  },

  {
    path: "/restricted",
    name: "Restricted",
    component: () =>
      /* webpackChunkName: "view-[request]" */ import(
        "@/components/error/403.vue"
      ),
  },
];
