const IndexDaftarGroup = () =>
  /* webpackChunkName: "view-[request]" */ import(
    "@/views/DaftarGroup/index.vue"
  );
const ListDaftarGroup = () =>
  /* webpackChunkName: "view-[request]" */ import(
    "@/views/DaftarGroup/list.vue"
  );

const IndexSubDataGroup = () =>
  /* webpackChunkName: "view-[request]" */ import(
    "@/views/DaftarGroup/sub/index.vue"
  );

const ListDaftarPT = () =>
  /* webpackChunkName: "view-[request]" */ import(
    "@/views/DaftarGroup/sub/listpt.vue"
  );

const ListDataPeserta = () =>
  /* webpackChunkName: "view-[request]" */ import(
    "@/views/DaftarGroup/sub/listpeserta.vue"
  );
const DetailPeserta = () =>
  /* webpackChunkName: "view-[request]" */ import(
    "@/views/DaftarGroup/sub/detailpeserta.vue"
  );

const ListBenefit = () =>
  /* webpackChunkName: "view-[request]" */ import(
    "@/views/DaftarGroup/sub/listbenefit.vue"
  );

const ListKetentuanUmum = () =>
  /* webpackChunkName: "view-[request]" */ import(
    "@/views/DaftarGroup/sub/listketentuanumum.vue"
  );

const TambahPeserta = () =>
  /* webpackChunkName: "view-[request]" */ import(
    "@/views/DaftarGroup/sub/tambahpeserta.vue"
  );
const EditPeserta = () =>
  /* webpackChunkName: "view-[request]" */ import(
    "@/views/DaftarGroup/sub/editpeserta.vue"
  );
export default [
  {
    path: "/daftar-group",
    component: IndexDaftarGroup,
    meta: { requiresAuth: true, Permission: "Daftar Group" },
    children: [
      {
        path: "",
        name: "ListDaftarGroup",
        component: ListDaftarGroup,
        meta: { requiresAuth: true, title: "Daftar Group" },
      },
    ],
  },
  {
    path: "/daftar-group",
    name: "IndexSubDataGroup",
    component: IndexSubDataGroup,
    meta: {
      requiresAuth: true,
      title: "Daftar PT",
      Permission: "Daftar Group",
    },
    children: [
      {
        path: "daftar-pt/:id",
        name: "ListDaftarPT",
        component: ListDaftarPT,
        meta: { requiresAuth: true, title: "Daftar PT" },
      },
      {
        path: "data-peserta/detail/:id/:idpeserta",
        name: "DetailPeserta",
        component: DetailPeserta,
        meta: { requiresAuth: true, title: "Detail Peserta" },
      },
      {
        path: "data-peserta/list/:id",
        name: "ListDataPeserta",
        component: ListDataPeserta,
        meta: { requiresAuth: true, title: "Data Peserta" },
      },

      {
        path: "data-peserta/tambah-peserta/:id",
        name: "TambahPeserta",
        component: TambahPeserta,
        meta: { requiresAuth: true, title: "Tambah Peserta" },
      },

      {
        path: "data-peserta/edit-peserta/:id/:idpeserta",
        name: "EditPeserta",
        component: EditPeserta,
        meta: { requiresAuth: true, title: "Edit Peserta" },
      },
      {
        path: "benefit/:id",
        name: "ListBenefit",
        component: ListBenefit,
        meta: { requiresAuth: true, title: "Benefit" },
      },

      {
        path: "ketentuan-umum/:id",
        name: "ListKetentuanUmum",
        component: ListKetentuanUmum,
        meta: { requiresAuth: true, title: "Ketentuan Umum" },
      },
    ],
  },
];
