import Vue from "vue";
import Vuex from "vuex";
import Auth from "./Auth.js";
import Claim from "./Claim.js";
import Group from "./Group.js";
import Benefit from "./Benefit.js";
import Peserta from "./Peserta.js";
import KetentuanUmum from "./KetentuanUmum.js";
import Perusahaan from "./Perusahaan.js";
import Dashboard from "./Dashboard.js";
import Report from "./report.js";

import AntrianData from "./AntrianData.js";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    isLoading: false,
    loadpeserta: false,
  },

  mutations: {},
  actions: {},
  modules: {
    Auth,
    Claim,
    Group,
    Benefit,
    Peserta,
    KetentuanUmum,
    Perusahaan,
    Dashboard,
    Report,
    AntrianData,
  },
});
