const IndexDashboard = () =>
  /* webpackChunkName: "view-[request]" */ import(
    "@/views/Dashboard/index.vue"
  );
const ListDashboard = () =>
  /* webpackChunkName: "view-[request]" */ import("@/views/Dashboard/list.vue");

const ListDashboardMovement = () =>
  /* webpackChunkName: "view-[request]" */ import(
    "@/views/Dashboard/listmovement.vue"
  );

export default [
  {
    path: "/detail-dashboard",
    component: IndexDashboard,
    meta: { requiresAuth: true, Permission: "Exception" },
    children: [
      {
        path: "",
        name: "ListDashboard",
        component: ListDashboard,
        meta: { requiresAuth: true, title: "Klaim" },
      },

      {
        path: "movement",
        name: "ListDashboardMovement",
        component: ListDashboardMovement,
        meta: { requiresAuth: true, title: "Movement" },
      },
    ],
  },
];
