const IndexReport = () =>
  /* webpackChunkName: "view-[request]" */ import("@/views/Report/index.vue");
const ListReport = () =>
  /* webpackChunkName: "view-[request]" */ import("@/views/Report/list.vue");

export default [
  {
    path: "/report",
    component: IndexReport,
    meta: { requiresAuth: true, Permission: "Exception" },
    children: [
      {
        path: "",
        name: "ListReport",
        component: ListReport,
        meta: { requiresAuth: true, title: "Report" },
      },
    ],
  },
];
