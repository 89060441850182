import $axios from "@/utils/axios.js";

const state = {};

const getters = {};

const mutations = {};

const actions = {
  getRekening(context, params) {
    this.state.isLoading = true;
    return new Promise((resolve, reject) => {
      $axios
        .get("/ketentuan-umum/" + params.job_order + "/rekening")
        .then((response) => {
          this.state.isLoading = false;
          resolve(response);
        })
        .catch((error) => {
          this.state.isLoading = false;
          reject(error);
        });
    });
  },
  getDateDisabled(context, params) {
    this.state.isLoading = true;
    return new Promise((resolve, reject) => {
      $axios
        .get(
          "/ketentuan-umum/" +
            params.job_order +
            "/toleransi/" +
            params.movement
        )
        .then((response) => {
          this.state.isLoading = false;
          resolve(response);
        })
        .catch((error) => {
          this.state.isLoading = false;
          reject(error);
        });
    });
  },
  ReadNotif(context, params) {
    this.state.isLoading = true;
    return new Promise((resolve, reject) => {
      $axios
        .get("/notifikasi/read/" + params.id)
        .then((response) => {
          this.state.isLoading = false;
          resolve(response);
        })
        .catch((error) => {
          this.state.isLoading = false;
          reject(error);
        });
    });
  },
  GetNotification(context, params) {
    this.state.isLoading = true;
    return new Promise((resolve, reject) => {
      $axios
        .get("/notifikasi", {
          params: {
            ...params,
          },
        })
        .then((response) => {
          this.state.isLoading = false;
          resolve(response);
        })
        .catch((error) => {
          this.state.isLoading = false;
          reject(error);
        });
    });
  },
  GetKetentuanUmum(context, params) {
    this.state.isLoading = true;
    return new Promise((resolve, reject) => {
      $axios
        .get("/ketentuan-umum/" + params.id)
        .then((response) => {
          this.state.isLoading = false;
          resolve(response);
        })
        .catch((error) => {
          this.state.isLoading = false;
          reject(error);
        });
    });
  },
};

export default {
  namespace: true,
  state,
  getters,
  mutations,
  actions,
};
