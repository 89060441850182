import $axios from "@/utils/axios.js";
import $router from "@/router/index.js";
const controller = new AbortController();

const state = {
  token: localStorage.getItem("token"),
  permission: JSON.parse(localStorage.getItem("permission")),
  data: JSON.parse(localStorage.getItem("data")),
  company: JSON.parse(localStorage.getItem("company")),
};

const getters = {
  //fungsi is auth ini akan bernilai true atau false
  isAuth: (state) => {
    return (
      state.token != "null" && state.token != null && state.token != "undefined"
    );
  },
};

const mutations = {
  retrieveToken(state, { token, data, permission }) {
    state.token = token;
    state.permission = permission;
    state.data = data;
  },

  removeToken(state) {
    state.token = null;
    state.data = null;
    state.permission = null;
  },
};

const actions = {
  Unautorized(context) {
    localStorage.removeItem("token");
    localStorage.removeItem("data");
    localStorage.removeItem("permission");
    localStorage.removeItem("locked");

    context.commit("removeToken");
    $router.push({ name: "Login" });
  },
  NewPassword(context, params) {
    this.state.isLoading = true;
    return new Promise((resolve, reject) => {
      $axios
        .post("/set-new-password/" + params.id, {
          ...params,
        })
        .then((response) => {
          this.state.isLoading = false;
          resolve(response);
        })
        .catch((error) => {
          this.state.isLoading = false;
          reject(error);
        });
    });
  },
  SentOTP(context, params) {
    this.state.isLoading = true;
    return new Promise((resolve, reject) => {
      $axios
        .post("/post-otp", {
          ...params,
        })
        .then((response) => {
          this.state.isLoading = false;
          resolve(response);
        })
        .catch((error) => {
          this.state.isLoading = false;
          reject(error);
        });
    });
  },

  GetOTP(context, params) {
    this.state.isLoading = true;
    return new Promise((resolve, reject) => {
      $axios
        .post("/forgot-password", {
          ...params,
        })
        .then((response) => {
          this.state.isLoading = false;
          resolve(response);
        })
        .catch((error) => {
          this.state.isLoading = false;
          reject(error);
        });
    });
  },

  retrieveToken(context, credentials) {
    this.state.isLoading = true;
    return new Promise((resolve, reject) => {
      $axios
        .post("/login", {
          email: credentials.email,
          password: credentials.password,
        })
        .then((response) => {
          //alert(response.data.data.access_token)
          const token = response.data.token;
          const data = response.data;
          const permission = response.data.permission;
          localStorage.setItem("locked", false);
          localStorage.setItem("token", token);
          localStorage.setItem("data", JSON.stringify(data));
          localStorage.setItem("permission", JSON.stringify(permission));
          context.commit("retrieveToken", { token, data, permission });
          //alert('tes')
          //alert(this.$socket())

          this.state.isLoading = false;
          resolve(response.data);
        })
        .catch((error) => {
          this.state.isLoading = false;
          //console.log(error);
          reject(error);
        });
    });
  },

  GetDetailProfile(context, params) {
    this.state.isLoading = true;
    return new Promise((resolve, reject) => {
      $axios
        .get("/user-management/edit/" + params.id)
        .then((response) => {
          this.state.isLoading = false;
          resolve(response);
        })
        .catch((error) => {
          this.state.isLoading = false;
          reject(error);
        });
    });
  },

  UpdateProfile(context, params) {
    this.state.isLoading = true;
    return new Promise((resolve, reject) => {
      $axios
        .put("/user-management/edit/" + params.id, {
          ...params,
        })
        .then((response) => {
          this.state.isLoading = false;
          resolve(response);
        })
        .catch((error) => {
          this.state.isLoading = false;
          reject(error);
        });
    });
  },

  Logout(context) {
    this.state.isLoading = true;
    return new Promise((resolve, reject) => {
      $axios
        .get("/logout")
        .then((response) => {
          localStorage.clear();
          $router.push({ name: "Login" });
          //controller.abort();
          this.state.isLoading = false;
          context.commit("removeToken");
          resolve(response);
        })
        .catch((error) => {
          this.state.isLoading = false;
          console.log(error);
          reject(error);
        });
    });
  },

  ResetPassword(context, params) {
    this.state.isLoading = true;
    return new Promise((resolve, reject) => {
      $axios
        .put("/user-management/reset-password/" + params.id, {
          ...params,
        })
        .then((response) => {
          this.state.isLoading = false;
          resolve(response);
        })
        .catch((error) => {
          this.state.isLoading = false;
          reject(error);
        });
    });
  },
};

export default {
  namespace: true,
  state,
  getters,
  mutations,
  actions,
};
