import $axios from "@/utils/axios.js";

const state = {};

const getters = {};

const mutations = {};

const actions = {
  getKaryawanfield(context, params) {
    this.state.isLoading = true;
    return new Promise((resolve, reject) => {
      $axios
        .get(
          "/peserta/karyawan/" +
            params.id_job_order +
            "/detail/" +
            params.id_peserta
        )
        .then((response) => {
          this.state.isLoading = false;
          resolve(response);
        })
        .catch((error) => {
          this.state.isLoading = false;
          reject(error);
        });
    });
  },
  GetKaryawan(context, params) {
    this.state.isLoading = true;
    return new Promise((resolve, reject) => {
      $axios
        .get("/peserta/karyawan/" + params.id, {
          params: {
            search: params.search,
          },
        })
        .then((response) => {
          this.state.isLoading = false;
          resolve(response);
        })
        .catch((error) => {
          this.state.isLoading = false;
          reject(error);
        });
    });
  },
  InsertPeserta(context, params) {
    this.state.isLoading = true;
    return new Promise((resolve, reject) => {
      $axios
        .post("/peserta/add/" + params.nama_group, {
          ...params,
        })
        .then((response) => {
          this.state.isLoading = false;
          resolve(response);
        })
        .catch((error) => {
          this.state.isLoading = false;
          reject(error);
        });
    });
  },
  getTanggunganTemporary(context, params) {
    this.state.isLoading = true;
    return new Promise((resolve, reject) => {
      $axios
        .get("/peserta/tanggungan/" + params.id)
        .then((response) => {
          this.state.isLoading = false;
          resolve(response);
        })
        .catch((error) => {
          this.state.isLoading = false;
          reject(error);
        });
    });
  },
  ChangeChangePesertaAntrian(context, params) {
    this.state.isLoading = true;
    return new Promise((resolve, reject) => {
      $axios
        .put(
          "/waiting-list/change/" + params.id_peserta + "/" + params.nama_group,
          {
            ...params,
          }
        )
        .then((response) => {
          this.state.isLoading = false;
          resolve(response);
        })
        .catch((error) => {
          this.state.isLoading = false;
          reject(error);
        });
    });
  },
  ChangeAddPesertaAntrian(context, params) {
    this.state.isLoading = true;
    return new Promise((resolve, reject) => {
      $axios
        .put(
          "/waiting-list/add/" + params.id_peserta + "/" + params.nama_group,
          {
            ...params,
          }
        )
        .then((response) => {
          this.state.isLoading = false;
          resolve(response);
        })
        .catch((error) => {
          this.state.isLoading = false;
          reject(error);
        });
    });
  },
  ChangePeserta(context, params) {
    this.state.isLoading = true;
    return new Promise((resolve, reject) => {
      $axios
        .post("/peserta/change/" + params.id_peserta, {
          ...params,
        })
        .then((response) => {
          this.state.isLoading = false;
          resolve(response);
        })
        .catch((error) => {
          this.state.isLoading = false;
          reject(error);
        });
    });
  },
  getKategoriUsia(context, params) {
    this.state.isLoading = true;
    return new Promise((resolve, reject) => {
      $axios
        .get("/peserta/pengikat-benefit/" + params.id)
        .then((response) => {
          this.state.isLoading = false;
          resolve(response);
        })
        .catch((error) => {
          this.state.isLoading = false;
          reject(error);
        });
    });
  },
  getTitleBenefit(context, params) {
    this.state.isLoading = true;
    return new Promise((resolve, reject) => {
      $axios
        .post("/peserta/title-benefit/" + params.id, {
          jenis_kelamin: params.jenis_kelamin,
          status: params.status,
        })
        .then((response) => {
          this.state.isLoading = false;
          resolve(response);
        })
        .catch((error) => {
          this.state.isLoading = false;
          reject(error);
        });
    });
  },
  ActionDataPesertaQue(context, params) {
    this.state.isLoading = true;
    return new Promise((resolve, reject) => {
      $axios
        .put(
          "/waiting-list/" +
            params.link +
            "/" +
            params.id +
            "/" +
            params.joborder,
          {
            id_peserta: params.id_peserta,
            tgl_efektif: params.tgl_efektif,
            nama_perusahaan: params.nama_perusahaan,
          }
        )
        .then((response) => {
          this.state.isLoading = false;
          resolve(response);
        })
        .catch((error) => {
          this.state.isLoading = false;
          reject(error);
        });
    });
  },
  ActionPesertaBeforeUpdate(context, params) {
    this.state.isLoading = true;
    return new Promise((resolve, reject) => {
      $axios
        .post("/peserta-before-update/reaktif/" + params.id_job_order, {
          id: params.id,
          tgl_efektif: params.tgl_efektif,
        })
        .then((response) => {
          this.state.isLoading = false;
          resolve(response);
        })
        .catch((error) => {
          this.state.isLoading = false;
          reject(error);
        });
    });
  },
  ActionDataPeserta(context, params) {
    this.state.isLoading = true;
    return new Promise((resolve, reject) => {
      $axios
        .post("/peserta/" + params.link + "/" + params.id, {
          id_peserta: params.id_peserta,
          tgl_efektif: params.tgl_efektif,
          nama_perusahaan: params.nama_perusahaan,
        })
        .then((response) => {
          this.state.isLoading = false;
          resolve(response);
        })
        .catch((error) => {
          this.state.isLoading = false;
          reject(error);
        });
    });
  },
  GetDownload(context, params) {
    this.state.isLoading = true;
    return new Promise((resolve, reject) => {
      $axios
        .get("/templates/" + params.id_job_order + "/" + params.jenis_asuransi)
        .then((response) => {
          this.state.isLoading = false;
          resolve(response);
        })
        .catch((error) => {
          this.state.isLoading = false;
          reject(error);
        });
    });
  },
  // GetHistoryRevisi(context, params) {
  //   this.state.isLoading = true;
  //   return new Promise((resolve, reject) => {
  //     $axios
  //       .get("/peserta/history-perubahan/" + params.id)
  //       .then((response) => {
  //         this.state.isLoading = false;
  //         resolve(response);
  //       })
  //       .catch((error) => {
  //         this.state.isLoading = false;
  //         reject(error);
  //       });
  //   });
  // },
  GetHistoryLog(context, params) {
    this.state.isLoading = true;
    return new Promise((resolve, reject) => {
      $axios
        .get("/peserta/log/movement/history/" + params.id)
        .then((response) => {
          this.state.isLoading = false;
          resolve(response);
        })
        .catch((error) => {
          this.state.isLoading = false;
          reject(error);
        });
    });
  },
  GetDetailPesertaTemp(context, params) {
    this.state.isLoading = true;
    return new Promise((resolve, reject) => {
      $axios
        .get("/waiting-list/" + params.id)
        .then((response) => {
          this.state.isLoading = false;
          resolve(response);
        })
        .catch((error) => {
          this.state.isLoading = false;
          reject(error);
        });
    });
  },
  GetDetailPesertaDashboard(context, params) {
    this.state.isLoading = true;
    return new Promise((resolve, reject) => {
      $axios
        .get("/peserta/detail-temp/" + params.id)
        .then((response) => {
          this.state.isLoading = false;
          resolve(response);
        })
        .catch((error) => {
          this.state.isLoading = false;
          reject(error);
        });
    });
  },
  GetDetailPeserta(context, params) {
    this.state.isLoading = true;
    return new Promise((resolve, reject) => {
      $axios
        .get(
          "/" +
            (params.status == "coming-movement"
              ? "peserta-before-update"
              : "peserta") +
            "/detail/" +
            params.id
        )
        .then((response) => {
          this.state.isLoading = false;
          resolve(response);
        })
        .catch((error) => {
          this.state.isLoading = false;
          reject(error);
        });
    });
  },
  GetPlan(context, params) {
    this.state.isLoading = true;
    return new Promise((resolve, reject) => {
      $axios
        .get("/produk/" + params.plan)
        .then((response) => {
          this.state.isLoading = false;
          resolve(response);
        })
        .catch((error) => {
          this.state.isLoading = false;
          reject(error);
        });
    });
  },
  getDataPeserta(context, params) {
    this.state.loadpeserta = true;
    return new Promise((resolve, reject) => {
      $axios
        .get(
          "/" +
            (params.menu == "coming-movement"
              ? "peserta-before-update"
              : "peserta") +
            "/" +
            params.id +
            "/" +
            (params.id_perusahaan == null ? "-" : params.id_perusahaan) +
            "/" +
            (params.menu == null
              ? "aktif"
              : params.menu == "nonaktif"
              ? "nonaktif"
              : "coming-movement"),
          {
            params: {
              ...params,
            },
          }
        )
        .then((response) => {
          this.state.loadpeserta = false;
          resolve(response);
        })
        .catch((error) => {
          this.state.loadpeserta = false;
          reject(error);
        });
    });
  },

  ExportAllPeserta(context, values) {
    let postConfig = {
      responseType: "arraybuffer",
      headers: {
        "Content-Type": "application/vnd.ms-excel",
      },
    };
    this.state.isLoading = true;
    //console.log (values)
    return new Promise((resolve, reject) => {
      $axios
        .post(
          "/peserta/export/group",
          {
            ...values,
          },
          postConfig
        )
        .then((response) => {
          const url = window.URL.createObjectURL(
            new Blob([response.data], { type: "application/vnd.ms-excel" })
          );
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute(
            "download",
            "data-peserta-all-" + new Date().getTime() + ".xlsx"
          ); //or any other extension
          document.body.appendChild(link);
          link.click();

          document.body.removeChild(link);

          //const message = response.data.message;
          this.state.isLoading = false;
          resolve(response);
        })
        .catch((error) => {
          this.state.isLoading = false;
          reject(error);
        });
    });
  },

  ExportDataPeserta(context, values) {
    let postConfig = {
      responseType: "arraybuffer",
      headers: {
        "Content-Type": "application/vnd.ms-excel",
      },
    };
    this.state.isLoading = true;
    //console.log (values)
    return new Promise((resolve, reject) => {
      $axios
        .post(
          "/peserta/export",
          {
            ...values,
          },
          postConfig
        )
        .then((response) => {
          const url = window.URL.createObjectURL(
            new Blob([response.data], { type: "application/vnd.ms-excel" })
          );
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute(
            "download",
            "data-selected-peserta-" + new Date().getTime() + ".xlsx"
          ); //or any other extension
          document.body.appendChild(link);
          link.click();

          document.body.removeChild(link);

          //const message = response.data.message;
          this.state.isLoading = false;
          resolve(response);
        })
        .catch((error) => {
          this.state.isLoading = false;
          reject(error);
        });
    });
  },
};

export default {
  namespace: true,
  state,
  getters,
  mutations,
  actions,
};
