const IndexProfileUser = () =>
  /* webpackChunkName: "view-[request]" */ import("@/views/Profile/index.vue");
const ProfileUser = () =>
  /* webpackChunkName: "view-[request]" */ import(
    "@/views/Profile/Profile.vue"
  );

export default [
  {
    path: "/profile",
    component: IndexProfileUser,
    meta: {
      requiresAuth: true,
      Permission: "Exception",
    },
    children: [
      {
        path: "",
        name: "ProfileUser",
        component: ProfileUser,
        meta: { requiresAuth: true, title: "Detail Profile" },
      },
    ],
  },
];
