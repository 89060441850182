import $axios from "@/utils/axios.js";

const state = {
  uploadPercentage: 0,
};

const getters = {};

const mutations = {};

const actions = {
  ImportDaftarPT(context, params) {
    let ini = this;
    this.state.isLoading = true;
    console.log(params);
    const formData = new FormData();
    formData.append(
      "file_data_peserta",
      params.File == undefined ? "" : params.File
    );

    if (params.id_antrian != undefined) {
      formData.append(
        "id_antrian",
        params.id_antrian == undefined ? "" : params.id_antrian
      );
    }
    return new Promise((resolve, reject) => {
      $axios
        .post("/peserta/import/" + params.id, formData, {
          headers: { "Content-Type": "multipart/form-data" },
          onUploadProgress: function (progressEvent) {
            const uploadPercentage = parseInt(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
            ini.state.AntrianData.uploadPercentage = uploadPercentage;
          },
        })
        .then((response) => {
          this.state.AntrianData.uploadPercentage = 0;
          this.state.isLoading = false;
          resolve(response);
        })
        .catch((error) => {
          this.state.AntrianData.uploadPercentage = 0;
          this.state.isLoading = false;
          reject(error);
        });
    });
  },
  DownloadBatch(context, values) {
    let postConfig = {
      responseType: "arraybuffer",
      headers: {
        "Content-Type": "application/vnd.ms-excel",
      },
    };
    this.state.isLoading = true;
    //console.log (values)
    return new Promise((resolve, reject) => {
      $axios
        .post(
          "/antrian-group/export",
          {
            ...values,
          },
          postConfig
        )
        .then((response) => {
          const url = window.URL.createObjectURL(
            new Blob([response.data], { type: "application/vnd.ms-excel" })
          );
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", values.uid + ".xlsx"); //or any other extension
          document.body.appendChild(link);
          link.click();

          document.body.removeChild(link);

          //const message = response.data.message;
          this.state.isLoading = false;
          resolve(response);
        })
        .catch((error) => {
          this.state.isLoading = false;
          reject(error);
        });
    });
  },

  ImportDataGroup(context, params) {
    let ini = this;
    this.state.isLoading = true;
    console.log(params);
    const formData = new FormData();
    formData.append(
      "file_data_peserta",
      params.File == undefined ? "" : params.File
    );

    if (params.id_antrian != undefined) {
      formData.append(
        "id_antrian",
        params.id_antrian == undefined ? "" : params.id_antrian
      );
    }
    return new Promise((resolve, reject) => {
      $axios
        .post("/peserta/import/" + params.id, formData, {
          headers: { "Content-Type": "multipart/form-data" },
          onUploadProgress: function (progressEvent) {
            const uploadPercentage = parseInt(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
            ini.state.AntrianData.uploadPercentage = uploadPercentage;
          },
        })
        .then((response) => {
          this.state.AntrianData.uploadPercentage = 0;
          this.state.isLoading = false;
          resolve(response);
        })
        .catch((error) => {
          this.state.AntrianData.uploadPercentage = 0;
          this.state.isLoading = false;
          reject(error);
        });
    });
  },
  GetCheckTime() {
    this.state.isLoading = true;
    return new Promise((resolve, reject) => {
      $axios
        .get("/check-time")
        .then((response) => {
          this.state.isLoading = false;
          resolve(response);
        })
        .catch((error) => {
          this.state.isLoading = false;
          reject(error);
        });
    });
  },
  GetWaitingList(context, params) {
    this.state.isLoading = true;
    return new Promise((resolve, reject) => {
      $axios
        .get("/waiting-list", {
          params: {
            ...params,
          },
        })
        .then((response) => {
          this.state.isLoading = false;
          resolve(response);
        })
        .catch((error) => {
          this.state.isLoading = false;
          reject(error);
        });
    });
  },
  GetGroupAntrianData(context, params) {
    this.state.isLoading = true;
    return new Promise((resolve, reject) => {
      $axios
        .get("/antrian-group", {
          params: {
            ...params,
          },
        })
        .then((response) => {
          this.state.isLoading = false;
          resolve(response);
        })
        .catch((error) => {
          this.state.isLoading = false;
          reject(error);
        });
    });
  },
  getFailedUpload(context, params) {
    this.state.isLoading = true;
    return new Promise((resolve, reject) => {
      $axios
        .get("antrian-group/failed", {
          params: {
            ...params,
          },
        })
        .then((response) => {
          this.state.isLoading = false;
          resolve(response);
        })
        .catch((error) => {
          this.state.isLoading = false;
          reject(error);
        });
    });
  },

  MarkReUpload(context, params) {
    this.state.isLoading = true;
    return new Promise((resolve, reject) => {
      $axios
        .put("antrian-group/mark-to-download/" + params.id)
        .then((response) => {
          this.state.isLoading = false;
          resolve(response);
        })
        .catch((error) => {
          this.state.isLoading = false;
          reject(error);
        });
    });
  },

  GetCountDown(context, params) {
    this.state.isLoading = true;
    return new Promise((resolve, reject) => {
      $axios
        .get("waiting-list/get/countdown")
        .then((response) => {
          this.state.isLoading = false;
          resolve(response);
        })
        .catch((error) => {
          this.state.isLoading = false;
          reject(error);
        });
    });
  },

  PostMarkDownload(context, params) {
    this.state.isLoading = true;
    return new Promise((resolve, reject) => {
      $axios
        .put("antrian-group/mark-to-reupload/" + params.id)
        .then((response) => {
          this.state.isLoading = false;
          resolve(response);
        })
        .catch((error) => {
          this.state.isLoading = false;
          reject(error);
        });
    });
  },
  HapusDataAntrian(context, params) {
    this.state.isLoading = true;
    return new Promise((resolve, reject) => {
      $axios
        .put("/waiting-list/delete/" + params.id)
        .then((response) => {
          this.state.isLoading = false;
          resolve(response);
        })
        .catch((error) => {
          this.state.isLoading = false;
          reject(error);
        });
    });
  },
};

export default {
  namespace: true,
  state,
  getters,
  mutations,
  actions,
};
