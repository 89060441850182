export default {
  methods: {
    $can(permissionName) {
      let Permission = this.$store.state.auth.permission;
      // let Permission = ["Klaim", "QueAntrianData", "DaftarGroup"];
      console.log(Permission);
      if (typeof Permission != "undefined") {
        return Permission.indexOf(permissionName) !== -1;
      }
    },
  },
};
