import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store/index.js";
import Home from "./home";
import Profile from "./profile";
import RekapKlaim from "./rekapklaim";
import AntrianData from "./antrian-data";
import DaftarGroup from "./daftar-group";
import Dashboard from "./dashboard";
import Report from "./Report";
import Axios from "axios";

Vue.prototype.$http = Axios;
Vue.use(VueRouter);

const routes = []
  .concat(Home)
  .concat(RekapKlaim)
  .concat(Profile)
  .concat(AntrianData)
  .concat(DaftarGroup)
  .concat(Dashboard)
  .concat(Report);

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

// router.beforeRouteUpdate((to, from, next) => {
//   next();
// })

router.beforeEach((to, from, next) => {
  let auth = store.getters.isAuth;
  if (to.meta.title) {
    document.title = "ANDIKA HR Portal - " + to.meta.title;
  }
  if (to.name !== "Login" && !auth) {
    next({
      path: "/login",
      replace: true,
    });
  } else {
    let Permission = store.state.Auth.permission;

    let routerpermission = to.matched[0].meta.Permission;
    //console.log(to.matched);
    if (to.matched.some((record) => record.meta.requiresAuth)) {
      if (
        Permission.indexOf(routerpermission) !== -1 ||
        routerpermission === "Exception"
      ) {
        next();
      } else {
        if (to.name !== "Restricted") {
          next({
            path: "/restricted",
            replace: true,
          });
        } else {
          next();
        }
      }
    } else {
      next();
    }
  }
});

export default router;
