const IndexKlaim = () =>
  /* webpackChunkName: "view-[request]" */ import(
    "@/views/RekapKlaim/index.vue"
  );
const ListKlaim = () =>
  /* webpackChunkName: "view-[request]" */ import(
    "@/views/RekapKlaim/list.vue"
  );

const DetailKlaim = () =>
  /* webpackChunkName: "view-[request]" */ import(
    "@/views/RekapKlaim/detail.vue"
  );

export default [
  {
    path: "/rekap-claim",
    component: IndexKlaim,
    meta: { requiresAuth: true, Permission: "Rekap Klaim" },
    children: [
      {
        path: "",
        name: "ListKlaim",
        component: ListKlaim,
        meta: { requiresAuth: true, title: "Rekap Klaim" },
      },

      {
        path: "detail/:id",
        name: "DetailKlaim",
        component: DetailKlaim,
        meta: { requiresAuth: true, title: "Detail Klaim" },
      },
    ],
  },
];
