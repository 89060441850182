const IndexQueAntrianData = () =>
  /* webpackChunkName: "view-[request]" */ import(
    "@/views/AntrianData/index.vue"
  );
const ListQueAntrianData = () =>
  /* webpackChunkName: "view-[request]" */ import(
    "@/views/AntrianData/que.vue"
  );

const ListFailedAntrianData = () =>
  /* webpackChunkName: "view-[request]" */ import(
    "@/views/AntrianData/uploadfailed.vue"
  );

const EditPesertaTemp = () =>
  /* webpackChunkName: "view-[request]" */ import(
    "@/views/AntrianData/edit.vue"
  );

export default [
  {
    path: "/antrian-data",
    component: IndexQueAntrianData,
    meta: { requiresAuth: true, Permission: "Antrian Group" },
    children: [
      {
        path: "",
        name: "ListQueAntrianData",
        component: ListQueAntrianData,
        meta: { requiresAuth: true, title: "Antrian Data" },
      },
      {
        path: "edit/:id",
        name: "EditPesertaTemp",
        component: EditPesertaTemp,
        meta: { requiresAuth: true, title: "Edit Peserta Temporary" },
      },

      {
        path: "failed",
        name: "ListFailedAntrianData",
        component: ListFailedAntrianData,
        meta: { requiresAuth: true, title: "Upload Failed" },
      },
    ],
  },
  // {

  // }
];
